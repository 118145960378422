'use client'
import { createContext, ReactNode, useContext, useState } from 'react'
import ErrorModal from '@/app/components/core/ErrorModal'

// Define a type for the context value
type ErrorContextType = {
  error: string | null
  type: string | null
  showError: (message: string, type: string) => void
  clearError: () => void
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined)

export const ErrorProvider = ({ children }: { children: ReactNode }) => {
  const [error, setError] = useState<string | null>(null)
  const [type, setType] = useState<string | null>(null)

  const showError = (message: string, type: string) => {
    setError(message);
    setType(type);
  }

  const clearError = () => {
    setError(null)
  }

  return (
    <ErrorContext.Provider value={{ error, type, showError, clearError }}>
      <ErrorModal />
      {children}
    </ErrorContext.Provider>
  )
}

export const useError = (): ErrorContextType => {
  const context = useContext(ErrorContext)
  if (!context) {
    throw new Error('useError must be used within an ErrorProvider')
  }
  return context
}
