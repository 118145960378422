'use client'
import { useError } from '@/app/components/providers/ErrorProvider'
import { Modal, ModalBody, ModalContent } from '@nextui-org/modal'
import { Button } from '@nextui-org/button'

export default function ErrorModal() {
  const { error, clearError, type } = useError()

  switch (type) {
    case 'success':
      return (
        <Modal
          backdrop={'transparent'}
          className={ 'items-center p-3 ' }
          classNames={{ wrapper: 'sm:items-start' }}
          isOpen={!!error}
          onClose={clearError}
          size='sm'
          placement={'top-center'}
        >
          <ModalContent>
            <ModalBody className={'flex flex-row gap-3 w-full items-center text-success'}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-12 h-12">
                <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
              </svg>
              <div className='text-sm text-black'>{error}</div>
            </ModalBody>
          </ModalContent>
        </Modal>)
    case 'warning':
        return (
          <Modal
            backdrop={'transparent'}
            className={ 'items-center p-3 ' }
            classNames={{ wrapper: 'sm:items-start' }}
            isOpen={!!error}
            onClose={clearError}
            size='sm'
            placement={'top-center'}
          >
            <ModalContent>
              <ModalBody className={'flex flex-row gap-3 w-full items-center text-warning'}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-12 h-12">
                  <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
                </svg>
                <div className='text-sm text-black'>{error}</div>
              </ModalBody>
            </ModalContent>
          </Modal>)
    case 'error':
    default:
      return (
          <Modal
            backdrop={'opaque'}
            className={ 'items-center p-3 ' }
            classNames={{ wrapper: 'sm:items-start' }}
            isOpen={!!error}
            onClose={clearError}
            size='sm'
            placement={'top-center'}
          >
            <ModalContent>
              <ModalBody className={'flex flex-col gap-3 w-full items-center text-danger'}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-16 h-16">
                  <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
                </svg>
                <div>ERROR</div>
                <div className='text-sm text-black'>{error}</div>
                <Button aria-label='Close' size='sm' color={'danger'} className='w-full' onClick={clearError}>Close</Button>
              </ModalBody>
            </ModalContent>
          </Modal>)
  }
}
