// Escape hatch for packages not updated to 'use client' yet
'use client'

import { ErrorProvider } from '@/app/components/providers/ErrorProvider'
import { CSPostHogProvider } from "@/app/components/providers/posthog";

import { NextUIProvider } from '@nextui-org/react'
import React from 'react'

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <>
      <NextUIProvider>
        <CSPostHogProvider>
          <ErrorProvider>{children}</ErrorProvider>
        </CSPostHogProvider>
      </NextUIProvider>
    </>
  )
}
